import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const EditCoupon = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();
  const { id } = useParams();

  const [dealers, setDealers] = useState([]);
  const dealersData = async () => {
    try {
      const response = await fetch("https://api.menuverse.ai/dealers/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
      const responseData = await response.json();

      setDealers(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };
  useEffect(() => {
    dealersData();
  }, []);

  const [formStructure, setFormStructure] = useState([]);

  useEffect(() => {
    const formFields = [
      {
        fieldName: "name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "150",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: states,
        // dbType: "integer",
        fieldLabel: "Name",
      },
      {
        fieldName: "dealer_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: dealers,
        dbType: "integer",
        fieldLabel: "Dealer",
      },
      {
        fieldName: "off_type",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",

        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: [
          { id: "Percentage", name: "Percentage" },
          { id: "Flat", name: "Flat" },
          { id: "Buy and get free", name: "Buy and get free" },
          {
            id: "Shop and get percentage off",
            name: "Shop and get percentage off",
          },
        ],
        dbType: "string",
        fieldLabel: "Off in",
      },
      {
        fieldName: "off",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "",

        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: "",
        // dbType: "integer",
        fieldLabel: "Off",
      },
      {
        fieldName: "buy_products",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "",

        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: "",
        // dbType: "integer",
        fieldLabel: "Least no. of products to buy",
      },
      {
        fieldName: "free_products",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "",

        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: "",
        // dbType: "integer",
        fieldLabel: "No. of free products",
      },

      {
        fieldName: "above_shop",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "",

        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: "",
        // dbType: "integer",
        fieldLabel: "Shop above",
      },
    ];

    const getData = async () => {
      let record = await fetch("https://api.menuverse.ai/coupons/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 201 || record.status === 200) {
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }
      record = await record.json();
      const dealerData = record.data;

      setFormStructure(
        formFields.map((field) => {
          return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
        })
      );
    };
    getData();
  }, [dealers]);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/coupons/update/" + id,
    successRedirect: "/coupons",
  };

  return (
    <>
      <MainWrapper title="Edit Coupon">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formStructure} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default EditCoupon;
