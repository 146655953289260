import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const EditServiceProductType = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();
  const [serviceCategoriesData, setserviceCategoriesData] = useState([]);
  const serviceCategories = async () => {
    try {
      const response = await fetch(
        "https://api.menuverse.ai/service_category/show",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setserviceCategoriesData(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  useEffect(() => {
    serviceCategories();
  }, []);

  const { id } = useParams();

  const [formStructure, setFormStructure] = useState([]);
  useEffect(() => {
    const formFields = [
      {
        fieldName: "name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Product Name",
      },
      {
        fieldName: "category_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: serviceCategoriesData,
        dbType: "integer",
        fieldLabel: "Category",
      },
    ];

    const getData = async () => {
      let record = await fetch(
        "https://api.menuverse.ai/service_product_type/show/" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (record.status === 201 || record.status === 200) {
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }

      record = await record.json();
      const dealerData = record.data;

      setFormStructure(
        formFields.map((field) => {
          return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
        })
      );
    };

    getData();
  }, [serviceCategoriesData]);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/service_product_type/update/" + id,
    successRedirect: "/service-product-type",
  };

  return (
    <>
      <MainWrapper title="Edit Service Product Type">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formStructure} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default EditServiceProductType;
