import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import MainWrapper from "../Main/MainWrapper";
import { getCookie } from "../../utils";
import { NavLink } from "react-router-dom";

const UserDetails = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState(null);

  const getRoleName = (roleId) => {
    const numericRoleId = parseInt(roleId, 10); // Convert roleId to a number
    switch (numericRoleId) {
      case 1:
        return "Admin";
      case 2:
        return "Dealer";
      case 3:
        return "Manager";
      case 4:
        return "Salesperson";
      default:
        return "Role not available";
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/users/getmydetails",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );
        setUserDetails(response.data.data);
      } catch (err) {
        console.error("Error fetching user details:", err);
        setError("Failed to fetch user details.");
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <MainWrapper title="My Profile">
      <div className="container mt-5">
        <h2 className="mb-4">User Details</h2>
        {error ? (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        ) : (
          <div className="card">
            <NavLink to="/update_profile">
              <button className="btn btn-danger">Edit Profile</button>
            </NavLink>

            <div className="card-body">
              <h5 className="card-title">
                {userDetails.name || "Name not available"}
              </h5>
              <p className="card-text">
                <strong>Email:</strong>{" "}
                {userDetails.email || "Email not available"}
              </p>
              <p className="card-text">
                <strong>Role:</strong> {getRoleName(userDetails.roleId)}
              </p>
              <p className="card-text">
                <strong>Phone Number:</strong>{" "}
                {userDetails.phoneNo || "Phone number not available"}
              </p>
            </div>
          </div>
        )}
      </div>
    </MainWrapper>
  );
};

export default UserDetails;
