import React, { useEffect } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const AddCity = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();

  const [states, setStates] = useState([]);
  const statesData = async () => {
    try {
      const response = await fetch("https://api.menuverse.ai/states/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setStates(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  useEffect(() => {
    statesData();
  }, []);
  const formFields = [
    {
      fieldName: "state_id",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: states,
      dbType: "integer",
      fieldLabel: "State",
    },
    {
      fieldName: "name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "City Name",
    },
  ];
  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/cities/create",
    successRedirect: "/cities",
  };

  return (
    <>
      <MainWrapper title="Add City">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formFields} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddCity;
