import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useEffect, useState } from "react";
import Table from "../Common/Table";
import { Link } from "react-router-dom";

const Salesperson = () => {
  const apiData = {
    url: "https://api.menuverse.ai/salespersons/showsalesperson",
    method: "GET",
    editUrl: "/edit-salesperson",
    deleteUrl: "https://api.menuverse.ai/salespersons/delete",
  };
  const excludedColumns = [
    "id",
    "dba",
    "tax_id",
    "city",
    "state",
    "zip",
    "fl_number",
    "will_Salesperson_mirror",
  ];

  return (
    <>
      <MainWrapper title={"Sales Persons"}>
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <Link
              to="/add-salesperson"
              className="sidenav-item-link float-right"
            >
              <button className="btn btn-sm btn-warning">+ Add</button>
            </Link>

            <Table apiData={apiData} excludedColumns={excludedColumns} />
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default Salesperson;
