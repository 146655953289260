import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useEffect, useState } from "react";
import Table from "../Common/Table";
import { Link } from "react-router-dom";

const Dealers = () => {
  const apiData = {
    url: "https://api.menuverse.ai/dealers/showdealers",
    method: "GET",
    editUrl: "/edit-dealer",
    deleteUrl: "https://api.menuverse.ai/dealers/delete",
  };
  const excludedColumns = [
    "id",
    "dba",
    "tax_id",
    "city",
    "state",
    "zip",
    "fl_number",
    "will_dealer_mirror",
  ];

  return (
    <>
      <MainWrapper title={"Dealers"}>
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <Link to="/add-dealer" className="sidenav-item-link float-right">
              <button className="btn btn-sm btn-warning">+ Add</button>
            </Link>

            <Table apiData={apiData} excludedColumns={excludedColumns} />
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default Dealers;
