import React, { forwardRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

const Textarea = forwardRef(
  (
    {
      fieldName,
      fieldType,
      fieldMask,
      fieldMaxLength,
      fieldRequired,
      fieldValue,
      formDataObj,
      value,
      fieldOptions,
      onData,
      validate,
      fieldLabel,
      fieldPlaceholder
    },
    ref
  ) => {
    fieldValue = fieldValue ? fieldValue : formDataObj[fieldName];
    const [fieldNewValue, setFieldValue] = useState(fieldValue);

    useEffect(() => {
      setFieldValue(fieldValue);
    }, [fieldValue]);
    const handleChange = (e) => {
      if (fieldRequired == "true") {
        validateField(e.target.value, e);
      }
      setFieldValue(e.target.value);
      formDataObj = { ...formDataObj, [fieldName]: e.target.value };
      onData(formDataObj);
      console.log(formDataObj);
    };

    const validateField = (givenValue, el) => {
      console.log("my parent", el.target.parentElement);
      const myParent = el.target.parentElement;
      if (fieldType === "textarea") {
        if (givenValue == "") {
          el.target.style.borderColor = "red";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "block";
          validate(false);
        } else {
          el.target.style.borderColor = "#e5e9f2";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "none";
          validate(true);
        }
      }
    };

    const validateFieldEmpty = (el) => {
      const myParent = el.target.parentElement;
      if (fieldType === "textarea") {
        if (el.target.value == "" && fieldRequired == "true") {
          el.target.style.borderColor = "red";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "block";
        }
      }
    };
    return (
      <div ref={ref} style={{ width: "100%" }}>
        <textarea
          className="form-control"
          name={fieldName}
          onChange={handleChange}
          value={fieldNewValue}
          placeholder={fieldPlaceholder}
          data-isrequired={fieldRequired == "true" ? "true" : "false"}
          onBlur={validateFieldEmpty}
        ></textarea>
        <div style={{ display: "block", width: "100%" }}>
          <span style={{ color: "red", display: "none" }}>
            This field is required
          </span>
        </div>
      </div>
    );
  }
);

export default Textarea;
