import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Dealers from "./components/Dealers/Dealers";
import AddDealer from "./components/Dealers/AddDealer";
import EditDealer from "./components/Dealers/EditDealer";
import ServiceCategories from "./components/ServiceCategories/ServiceCategoreis";
import AddServiceCategory from "./components/ServiceCategories/AddServiceCategory";
import EditServiceCategory from "./components/ServiceCategories/EditServiceCategory";
import ServiceProductTypes from "./components/ServiceProductTypes/ServiceProductTypes";
import AddServiceProductType from "./components/ServiceProductTypes/AddServiceProductType";
import EditServiceProductType from "./components/ServiceProductTypes/EditServiceProductType";
import Questions from "./components/Questionnare/Questions";
import AddQuestion from "./components/Questionnare/AddQuestion";
import EditQuestion from "./components/Questionnare/EditQuestion";
import Tests from "./components/Tests/Tests";
import AddTest from "./components/Tests/AddTest";
import EditTest from "./components/Tests/EditTest";
import Users from "./components/Users/Users";
// import Deals from "./components/NewDeals/NewDeals";
// import AddDeals from "./components/NewDeals/AddDeals";
// import EditDeals from "./components/NewDeals/EditNewDeal";

// admin
import NewDeals from "./components/NewDeals/NewDeals";
import AddNewDeals from "./components/NewDeals/AddNewDeals";
import EditNewDeals from "./components/NewDeals/EditNewDeal";

//
// vendors
import Vendors from "./components/Vendors/Vendors";
import AddVendor from "./components/Vendors/AddVandors";
import EditVendor from "./components/Vendors/EditVandors";

// salesTools
import SalesTools from "./components/SalesTools/SalesTools";
import AddSalesTools from "./components/SalesTools/AddSalesTools";
import EditSalestools from "./components/SalesTools/EditSalesTools";

// dms codes
import DmsCodeLists from "./components/DmsCode/DmsCodeList";
import AddDmsCodes from "./components/DmsCode/AddDmsCode";
import EditDmsCodes from "./components/DmsCode/EditDmsCode";

// partners
import Partner from "./components/Patner/PartnerList";
import AddPartner from "./components/Patner/AddPartner";
import EditPartner from "./components/Patner/EditPartner";

// products
import Products from "./components/Products/ProductList";
import AddProduct from "./components/Products/AddProduct";
import EditProduct from "./components/Products/EditProduct";

// States
import States from "./components/States/States";
import AddState from "./components/States/AddState";
import EditState from "./components/States/EditState";

//Cities
import Cities from "./components/Cities/Cities";
import AddCity from "./components/Cities/AddCity";
import EditCity from "./components/Cities/EditCity";

//Coupons
import Coupons from "./components/Coupons/Coupons";
import AddCoupon from "./components/Coupons/AddCoupon";
import EditCoupon from "./components/Coupons/EditCoupon";

import Login from "./components/Auth/Login";
import Coustomers from "./components/NewDeals/Customers";
import AddManager from "./components/Manager/AddManager";
import EditManager from "./components/Manager/EditManager";
import Managers from "./components/Manager/Managers";
import AddSalesperson from "./components/Salesperson/AddSalesperson";
import Salesperson from "./components/Salesperson/Salesperson";
import EditSaleperson from "./components/Salesperson/EditSalesperson";
import Reports from "./components/Reports/Reports";
import Comparison from "./components/Reports/Comparison";
import Edituser from "./components/Users/Edituser";
import UserDetails from "./components/Users/UserDetails";
import Refer from "./components/Refer/Refer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />


        <Route path="/add-dealer" element={<AddDealer />} />
        <Route path="/edit-dealer/:id" element={<EditDealer />} />
        <Route path="/dealers" element={<Dealers />} />

        <Route path="/reports" element={<Reports />} />
        <Route path="/Comparisonreports" element={<Comparison />} />

        <Route path="/add-manager" element={<AddManager />} />
        <Route path="/edit-manager/:id" element={<EditManager />} />
        <Route path="/managers" element={<Managers />} />

        <Route path="/add-salesperson" element={<AddSalesperson />} />
        <Route path="/edit-salesperson/:id" element={<EditSaleperson />} />
        <Route path="/salespersons" element={<Salesperson />} />


        <Route path="/service-categories" element={<ServiceCategories />} />
        <Route path="/add-service-category" element={<AddServiceCategory />} />
        <Route
          path="/edit-service-category/:id"
          element={<EditServiceCategory />}
        />
        <Route path="/service-product-type" element={<ServiceProductTypes />} />
        <Route
          path="/add-service-product-type"
          element={<AddServiceProductType />}
        />
        <Route
          path="/edit-service-product-type/:id"
          element={<EditServiceProductType />}
        />
        <Route path="/questions" element={<Questions />} />
        <Route path="/add-question" element={<AddQuestion />} />
        <Route path="/edit-question/:id" element={<EditQuestion />} />
        <Route path="/tests" element={<Tests />} />
        <Route path="/add-test" element={<AddTest />} />
        <Route path="/edit-test/:id" element={<EditTest />} />
        {/* / <Route path="/deals" element={<Deals />} />
        <Route path="/add-deal" element={<AddDeals />} />
        <Route path="/edit-deal/:id" element={<EditDeals />} /> */}
        {/* vendors */}
        <Route path="/vendors" element={<Vendors />} />
        <Route path="/add-vendor" element={<AddVendor />} />
        <Route path="/edit-vendor/:id" element={<EditVendor />} />
        {/* sales tools */}
        <Route path="/salestools" element={<SalesTools />} />
        <Route path="/add-saletool" element={<AddSalesTools />} />
        <Route path="/edit-saletool/:id" element={<EditSalestools />} />
        {/* new deals */}
        <Route path="/newdeals" element={<NewDeals />} />
        <Route path="/add-newdeal" element={<AddNewDeals />} />
        <Route path="/customers" element={<Coustomers />} />
        <Route path="/edit-newdeal/:id" element={<EditNewDeals />} />

        {/* dms code  */}
        <Route path="/dmscodes" element={<DmsCodeLists />} />
        <Route path="/add-dmscode" element={<AddDmsCodes />} />
        <Route path="/edit-dmscode/:id" element={<EditDmsCodes />} />

        {/* partners  */}
        <Route path="/partners" element={<Partner />} />
        <Route path="/add-partner" element={<AddPartner />} />
        <Route path="/edit-partner/:id" element={<EditPartner />} />

        {/* product  */}
        <Route path="/products" element={<Products />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/edit-product/:id" element={<EditProduct />} />

        {/* States  */}
        <Route path="/states" element={<States />} />
        <Route path="/add-state" element={<AddState />} />
        <Route path="/edit-state/:id" element={<EditState />} />

        {/* Cities  */}
        <Route path="/cities" element={<Cities />} />
        <Route path="/add-city" element={<AddCity />} />
        <Route path="/edit-city/:id" element={<EditCity />} />

        {/* Coupons  */}
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/add-coupon" element={<AddCoupon />} />
        <Route path="/edit-coupon/:id" element={<EditCoupon />} />

        {/* Auth */}

        <Route path="/login" element={<Login />} />
        <Route path="/users" element={<Users />} />
        <Route path="/Update_profile" element={<Edituser />} />
        <Route path="/myprofile" element={<UserDetails />} />
        <Route path="/refer" element={<Refer />} />
      </Routes>
    </Router>
  );
}

export default App;
