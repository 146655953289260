import React, { useState, useEffect } from "react";
import axios from "axios";
import MainWrapper from "../Main/MainWrapper";
import { getCookie } from "../../utils";
import { useNavigate } from "react-router-dom";

const EditUser = () => {
  // Get logged-in user data from cookies
  const loginedUser = JSON.parse(getCookie("loginData"));
  const id = loginedUser.data.id;
  const roleId = loginedUser.data.roleId;

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNo: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/users/getmydetails",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        const data = response.data.data;

        // Populate form fields with fetched user data
        setFormData({
          name: data.name || "",
          email: data.email || "",
          phoneNo: data.phoneNo || "",
        });
      } catch (err) {
        console.error("Error fetching user details:", err);
        setError("Failed to fetch user details.");
        setShowErrorToast(true);
      }
    };

    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to clean form data by removing null or empty values
  const cleanFormData = (data) => {
    const cleanedData = {};
    for (const key in data) {
      if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
        cleanedData[key] = data[key];
      }
    }
    return cleanedData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Clean up the form data before sending it in the request
    const cleanedData = cleanFormData(formData);

    try {
      const response = await axios.post(
        `https://api.menuverse.ai/users/update/${id}`,
        { ...cleanedData, roleId }, // Include roleId as part of the payload
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${loginedUser.data.token}`,
          },
        }
      );

      console.log("User updated successfully:", response.data);
      setSuccessMessage("User updated successfully!");
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 3000);
      navigate("/myprofile");
    } catch (err) {
      console.error("Error updating user:", err);
      setError("Failed to update user. Please try again.");
      setShowErrorToast(true);
      setTimeout(() => setShowErrorToast(false), 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainWrapper title="Edit Profile">
      <div className="container mt-5">
        <h2>Edit Profile</h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="phoneNo" className="form-label">
                Phone No
              </label>
              <input
                type="text"
                id="phoneNo"
                name="phoneNo"
                className="form-control"
                value={formData.phoneNo}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Updating..." : "Update User"}
          </button>
        </form>
      </div>

      {/* Toast Container */}
      <div
        aria-live="polite"
        aria-atomic="true"
        className="position-fixed top-0 start-50 translate-middle-x p-3"
        style={{ zIndex: 9999 }}
      >
        {/* Success Toast */}
        {showSuccessToast && (
          <div
            className="toast show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-autohide="true"
            data-bs-delay="3000"
          >
            <div className="toast-header">
              <strong className="me-auto">Success</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => setShowSuccessToast(false)}
              ></button>
            </div>
            <div className="toast-body">{successMessage}</div>
          </div>
        )}

        {/* Error Toast */}
        {showErrorToast && (
          <div
            className="toast show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-autohide="true"
            data-bs-delay="3000"
          >
            <div className="toast-header">
              <strong className="me-auto">Error</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => setShowErrorToast(false)}
              ></button>
            </div>
            <div className="toast-body">{error}</div>
          </div>
        )}
      </div>
    </MainWrapper>
  );
};

export default EditUser;
