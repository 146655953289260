import React, { useEffect } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";

const AddState = () => {
  const navigate = useNavigate();

  const countries = [{ name: "United States", id: 1 }];

  const formFields = [
    {
      fieldName: "country_id",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: countries,
      dbType: "integer",
      fieldLabel: "Country",
    },

    {
      fieldName: "name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "10",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "State Name",
    },
  ];

  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/states/create",
    successRedirect: "/states",
  };

  return (
    <>
      <MainWrapper title="Add State">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formFields} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddState;
