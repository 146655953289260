import React, { useEffect } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const AddCoupon = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();

  const [dealers, setDealers] = useState([]);
  const dealersData = async () => {
    try {
      const response = await fetch("https://api.menuverse.ai/dealers/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
      const responseData = await response.json();

      setDealers(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  useEffect(() => {
    dealersData();
  }, []);
  const formFields = [
    {
      fieldName: "name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "150",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      // fieldOptions: states,
      // dbType: "integer",
      fieldLabel: "Name",
    },
    {
      fieldName: "dealer_id",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: dealers,
      dbType: "integer",
      fieldLabel: "Dealer",
    },
    {
      fieldName: "off_type",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",

      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: [
        { id: "Percentage", name: "Percentage" },
        { id: "Flat", name: "Flat" },
        { id: "Buy and get free", name: "Buy and get free" },
        {
          id: "Shop and get percentage off",
          name: "Shop and get percentage off",
        },
      ],
      dbType: "string",
      fieldLabel: "Off in",
    },
    {
      fieldName: "off",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "",

      fieldDefaultValue: "",
      fieldValue: "",
      // fieldOptions: "",
      // dbType: "integer",
      fieldLabel: "Off",
    },
    {
      fieldName: "buy_products",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "",

      fieldDefaultValue: "",
      fieldValue: "",
      // fieldOptions: "",
      // dbType: "integer",
      fieldLabel: "Least no. of products to buy",
    },
    {
      fieldName: "free_products",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "",

      fieldDefaultValue: "",
      fieldValue: "",
      // fieldOptions: "",
      // dbType: "integer",
      fieldLabel: "No. of free products",
    },

    {
      fieldName: "above_shop",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "",

      fieldDefaultValue: "",
      fieldValue: "",
      // fieldOptions: "",
      // dbType: "integer",
      fieldLabel: "Shop above",
    },
  ];
  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/coupons/create",
    successRedirect: "/coupons",
  };

  return (
    <>
      <MainWrapper title="Add Coupon">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formFields} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddCoupon;
