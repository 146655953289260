import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";

const AddTest = () => {
  const navigate = useNavigate();

  // const cities = [
  //   { name: "Amritsar", id: 1 },
  //   { name: "Jalandhar", id: 2 },
  //   { name: "Ludhiana", id: 3 },
  // ];
  const columnThrees = [
    { name: "TestColumn 1", id: 1 },
    { name: "TestColumn 2", id: 2 },
    { name: "TestColumn 3", id: 3 },
  ];

  const formFields = [
    {
      fieldName: "columnOne",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
    },
    {
      fieldName: "columnTwo",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
    },
    {
      fieldName: "columnThree",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: columnThrees,
      dbType: "integer",
    },
    // {
    //   fieldName: "tax_id",
    //   fieldType: "text",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    // },
    // {
    //   fieldName: "zip",
    //   fieldType: "text",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    // },
    // {
    //   fieldName: "phone",
    //   fieldType: "text",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    // },
    // {
    //   fieldName: "fl_number",
    //   fieldType: "text",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    // },
    // {
    //   fieldName: "agreement_effective_date",
    //   fieldType: "date",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    // },
    // {
    //   fieldName: "kick_off_date",
    //   fieldType: "date",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    // },
    // {
    //   fieldName: "will_dealer_mirror",
    //   fieldType: "radio",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "yes",
    //   fieldLabel: "Will this Dealer mirror another Dealer's setup?",
    // },
    // {
    //   fieldName: "will_dealer_mirror",
    //   fieldType: "radio",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "no",
    //   fieldLabel: "Will this Dealer mirror another Dealer's setup?",
    // },
    // {
    //   fieldName: "city",
    //   fieldType: "select",
    //   fieldMask: "",
    //   fieldMaxLength: "",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "",
    //   fieldOptions: cities,
    //   dbType: "integer",
    // },
    // {
    //   fieldName: "state",
    //   fieldType: "select",
    //   fieldMask: "",
    //   fieldMaxLength: "",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "",
    //   fieldOptions: states,
    //   dbType: "integer",
    // },
    // {
    //   fieldName: "address",
    //   fieldType: "textarea",
    //   fieldMask: "",
    //   fieldMaxLength: "",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "",
    // },
  ];

  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/tests/create",
    successRedirect: "/tests",
  };

  return (
    <>
      <MainWrapper title="Add Test">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formFields} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddTest;
