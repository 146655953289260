import React from "react";
import $, { get } from "jquery";
import "datatables.net";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCookie } from "../../utils";
import Loader from "./Loader";

const Table = ({ apiData, excludedColumns }) => {
  const navigate = useNavigate();
  const loginedUser = JSON.parse(getCookie("loginData"));

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [loading, setLoading] = useState(false);

  // Fetch data function
  const fetchData = async () => {
    if (searchTerm) {
      setLoading(false); 
    }else {
      setLoading(true);
    }
    try {
      const response = await fetch(
        `${apiData.url}?page=${currentPage}&search=${searchTerm}`,
        {
          method: apiData.method,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const responseData = await response.json();
        setData(responseData.data);
        setTotalPages(responseData.last_page);
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
    } catch (error) {
      console.log("Error Occurred", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch data whenever currentPage or searchTerm changes
  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm]);

  // Initialize DataTable
  useEffect(() => {
    if (data.length > 0) {
      $.fn.dataTable.ext.errMode = "none";
      $("#productsTable2").DataTable({
        paging: false,
        info: false,
        lengthChange: false,
        scrollX: true,
        order: [[2, "asc"]],
        // language: {
        //   search: "_INPUT_",
        //   searchPlaceholder: "Search...",
        // },
        searching: false,
      });
      $("#productsTable2").css("marginTop", "-40px");
    }
  }, [data]);

  // Delete record
  const deleteRecord = async (id) => {
    try {
      const response = await fetch(apiData.deleteUrl + "/" + id, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 200 || response.status === 201) {
        const updatedData = data.filter((record) => record.id !== id);
        setData(updatedData);
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
    } catch (error) {
      console.log("Error Occurred", error);
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteRecord(id);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const url = window.location.href;
  const urlSplit = url.split('/');
  const lastPart = urlSplit[urlSplit.length - 1];
  console.log("Last part of URL:", lastPart);


  return (
    <div>
      {loading ? (
        <Loader /> // Show Loader while loading
      ) : (
        <div>
          {/* Search Input */}
          <div className="search-container">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          <table
            id="productsTable2"
            className="table table-hover table-product"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Actions</th>
                {data.length > 0 &&
                  Object.keys(data[0]).map((column, key) => {
                    if (!excludedColumns.includes(column)) {
                      return <th key={key}>{column.replace(/_/g, " ")}</th>;
                    }
                  })}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((record) => {
                  return (
                    <tr key={record.id}>
                      <td>
                      {lastPart !== "users" && (
                        <Link to={`${apiData.editUrl}/${record.id}`}>Edit</Link>
                      )}
                        <br />
                        {lastPart !== "newdeals" && ( // Check if lastPart is not "newdeals"
                          <a href="#" onClick={() => handleDelete(record.id)}>
                            Delete
                          </a>
                        )}
                      </td>
                      {Object.keys(data[0]).map((column) => {
                        if (!excludedColumns.includes(column)) {
                          return <td key={column}>{record[column]}</td>;
                        }
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {/* Pagination Controls */}
          {currentPage && totalPages > 1 && (
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &laquo; Previous
              </button>
              <button>{currentPage}</button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next &raquo;
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
