import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useEffect, useState } from "react";
import Table from "../Common/Table";
import { Link } from "react-router-dom";

const DmsCodeLists = () => {
  const apiData = {
    url: "https://api.menuverse.ai/dmscode/show",
    method: "GET",
    editUrl: "/edit-dmscode",
    deleteUrl: "https://api.menuverse.ai/dmscode/delete",
  };
  const excludedColumns = ["id"];

  return (
    <>
      <MainWrapper title={"Dms Code"}>
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <Link to="/add-dmscode" className="sidenav-item-link float-right">
              <button className="btn btn-sm btn-warning">+ Add</button>
            </Link>

            <Table apiData={apiData} excludedColumns={excludedColumns} />
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default DmsCodeLists;
