import React, { useState, useEffect } from "react";
import "./Loader.css"; // Create this file for the CSS styles

const Loader = () => {
  const [messageIndex, setMessageIndex] = useState(0);

  const messages = ["Now Loading.", "Now Loading..", "Now Loading..."];

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 500);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="container">
      <div className="loader"></div>
      <p>{messages[messageIndex]}</p>
    </div>
  );
};

export default Loader;
