import React, { useEffect, forwardRef } from "react";
import { useState } from "react";
import Select2 from "react-select2-wrapper";

const Select = forwardRef(
  (
    {
      fieldName,
      fieldType,
      fieldMask,
      fieldMaxLength,
      fieldRequired,
      fieldValue,
      formDataObj,
      fieldDefaultValue,
      select_multiple,
      value,
      multiple,
      multiples,
      fieldOptions,
      onData,
      fieldLabel,
      sectionIndex,
      defaultValue,
      validate,
      onSelectOption,
      onChange,
      fieldId,
      refreshedSelectOptions,
    },
    ref
  ) => {
    // console.log("Select FormObj ", formDataObj);

    fieldValue = fieldValue ? fieldValue : formDataObj[fieldName];

    const [mainVal, setMainVal] = useState("");
    // console.log("refreshedSelectOptions ", refreshedSelectOptions);
    const [fieldNewValue, setFieldValue] = useState(fieldValue);
    const [newFieldOptions , setNewFieldOptions] = useState(fieldOptions);

    

    useEffect(() => {
      console.log("fieldValue", fieldOptions);
      setFieldValue(fieldValue);
    }, [fieldValue]);
    const handleChange = (e) => {
      onChange(e);
      let selectedOptions;
      if (select_multiple == "true") {
        selectedOptions = Array.from(e.target.selectedOptions).map(
          (option) => option.value
        );
        console.log("e.target.selectedOptions", selectedOptions);
      } else {
        selectedOptions = e.target.value;
        console.log("e.target.selectedOptions", e.target.value);
      }
      if (fieldRequired == "true") {
        validateField(e.target.value, e);
      }
      setFieldValue(e.target.value);
      setMainVal(e.target.value);
      fieldDefaultValue = e.target.value;
      const hasMultiple = e.target.getAttribute("hasmultiple");
      const targetName = e.target.getAttribute("name");
      const targetIndex = e.target.getAttribute("sectionIndex");

      if (hasMultiple != "") {
        multiples = multiples.map((val, index) => {
          if (index == targetIndex) {
            return { ...val, [targetName]: selectedOptions };
          } else {
            return { ...val };
          }
        });
        onData(multiples);
      } else {
        formDataObj = { ...formDataObj, [fieldName]: selectedOptions };
        onData(formDataObj);
      }

      onSelectOption(selectedOptions);

      // console.log(formDataObj);
    };

    const validateField = (givenValue, el) => {
      console.log("my parent", el.target.parentElement);
      const myParent = el.target.parentElement;
      if (fieldType === "select") {
        if (givenValue == "") {
          el.target.style.borderColor = "red";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "block";
          validate(false);
        } else {
          el.target.style.borderColor = "#e5e9f2";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "none";
          validate(true);
        }
      }
    };

    const isArrayString = (str) => {
      try {
        const parsed = JSON.parse(str);
        return Array.isArray(parsed);
      } catch (e) {
        return false;
      }
    };

    useEffect(() => {
      if (multiple) {
        setMainVal(formDataObj[multiple][sectionIndex][fieldName]);
        setFieldValue(formDataObj[multiple][sectionIndex][fieldName]);
        console.log("select_setFieldValue", fieldNewValue);
      }
      if(fieldId){
      if(refreshedSelectOptions[fieldId]){
        console.log(refreshedSelectOptions[fieldId]);
        setNewFieldOptions(refreshedSelectOptions[fieldId]);
      }
    }else{
      setNewFieldOptions(fieldOptions);
    }

    // console.log("fieldValue", fieldOptions);
    }, [formDataObj, fieldOptions, refreshedSelectOptions]);

    if (
      select_multiple == "true" &&
      fieldNewValue != "" &&
      fieldNewValue != undefined &&
      isArrayString(fieldNewValue)
    ) {
      console.log("fieldNewValue: ", JSON.parse(fieldNewValue));
    }

    return (
      <div ref={ref} style={{ width: "100%" }}>
      <select
  id={fieldId || ""}
  sectionIndex={sectionIndex}
  className="form-control"
  name={fieldName}
  value={multiple ? mainVal : fieldNewValue}
  defaultValue={fieldDefaultValue}
  onChange={handleChange}
  hasmultiple={multiple ? multiple : ""}
  multiple={select_multiple === "true"}
  data-isrequired={fieldRequired === "true" ? "true" : "false"}
>
  <option value="">
    Select {fieldLabel ? fieldLabel.replace(/<[^>]*>/g, "").split(" ")[0] : fieldName}
  </option>

  {newFieldOptions.map(option => {
    const isSelected = multiple && fieldNewValue !== "" && fieldNewValue !== undefined && isArrayString(fieldNewValue)
      ? JSON.parse(fieldNewValue).includes(option.id)
      : fieldNewValue === option.id;

    return (
      <option key={option.id} value={option.id} selected={isSelected ? "selected" : ""}>
        {option.name}
      </option>
    );
  })}
</select>

      <div style={{ display: "block", width: "100%" }}>
        <span style={{ color: "red", display: "none" }}>
          This field is required
        </span>
      </div>
    </div>
    
    );
  }
);

export default Select;
