import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useEffect, useState } from "react";
import Table from "../Common/Table";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../utils";

const Products = () => {
  const navigate = useNavigate();
  const loginedUser = JSON.parse(getCookie("loginData"));
  const apiData = {
    url: "https://api.menuverse.ai/product/showProduct",
    method: "GET",
    editUrl: "/edit-product",
    deleteUrl: "https://api.menuverse.ai/product/delete",
  };
  const excludedColumns = ["id"];

  const handeSync = async (e) => {
    try {
      const response = await fetch(
        "https://api.menuverse.ai/fni_products/fetchProductPreRates",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();
      console.log(responseData);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  const handeRatesSync = async (e) => {
    try {
      const response = await fetch(
        "https://api.menuverse.ai/fni_products/fetchProductRates",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();
      console.log(responseData);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  return (
    <>
      <MainWrapper title={"Products"}>
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <button className="btn btn-sm btn-success" onClick={handeSync}>
              Sync Products Pre Rates
            </button>

            <button className="btn btn-sm btn-danger" onClick={handeRatesSync}>
              Sync Products Rates
            </button>
            <Link to="/add-product" className="sidenav-item-link float-right">
              <button className="btn btn-sm btn-warning">+ Add</button>
            </Link>

            <Table apiData={apiData} excludedColumns={excludedColumns} />
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default Products;
